import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import FormSuccess from "../components/FormSuccess/FormSuccess"

const FormSuccessPage = () => {
  return (
    <Layout>
      <SEO title="Success!" />
      <FormSuccess />
    </Layout>
  )
}

export default FormSuccessPage
