import React from "react"
import { Link } from "gatsby"
import { BsArrowLeft } from "react-icons/bs"
import classes from "./FormSuccess.module.scss"

const FormSuccess = () => {
  return (
    <div className={classes.FormSuccess}>
      <div>
        <h1>CHEERS!</h1>
        <p>Thank you for joining our newsletter.</p>
        <p>You can unsubscribe at any time.</p>
        <Link to="/">
          <span>
            <BsArrowLeft />
          </span>
          Back to our site
        </Link>
      </div>
    </div>
  )
}

export default FormSuccess
